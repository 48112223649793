import { useState } from "react";
import Footer from "../components/Footer";
import ModaalMessage from "../methods/ModalMessage";
import { useNavigate } from 'react-router-dom'

export default function Crypto() {
  const [key, setKey] = useState('')

  const navigate = useNavigate()

  const handleClick = (e) => {
    e.preventDefault();
    if (key === 'jqvxyz') {
      navigate('/articles')
    } else {
      setKey('')
    }
  }

    let currentKey = "5th"
    
    const levelCompleted = {
      lastLevel: '4th',
      currentLevel: '5th',
      peopleCompleted: 10

    }

    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <ModaalMessage level={levelCompleted.lastLevel} completed={levelCompleted.peopleCompleted}/>
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
           
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-emerald-500 font-semibold tracking-wide uppercase">
                Zodiac: Key 5
              </span>
              <span className="mt-2 block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Can0You0Solve0This0Xrl?
              </span>
            </h1>
            <p className="mt-8 text-lg text-stone-800 leading-8 text-center">
            Abg0rirelguvat0vf0nf0vg0frrzf.0Jr0yvir0va
            0n0jbeyq0bs0pbqr0rirelguvat0obhaq0ol0
            harkcynvanoyr0ynjf.0Jung0tbrf0hc0zhfg0
            pbzr0qbja.0Uryq0gbtrgure0ol0na0
            vaivfvoyr0tyhr0gung0fgergpurf0
            guebhtu0fcnpr0naq0gvzr.0Znggre0pna0
            arvgure0or0perngrq0abe0qrfgeblrq0va0
            gur0fnzr0snfuvba0gung0qvtvgny
            0zrzbel0rkvfgf0jv0guva0n0uneq0qevir.
            0Jr0ner0grpuabybtvpny0orvatf0qrfvtarq0
            ol0n0grpuabybtvpny0perngbe0va0n0
            fvzhyngrq0ernyvgl0jbexvat0hagvy0bhe0
            svyrf0qryrgr.0Na0raqyrff0ybbc0gung’f
            0bayl0rfpncr0vf0n0oernx0fgngrzrag.  
            </p>
            <p className="mt-8 text-center">Lbhe0xrl0vf: wndvkl</p>
            <div className="max-w-2xl mx-auto text-center mt-4">
            <form>
                <div className=" w-full inline-flex items-center justify-center px-5 py-3 ">
                  
                    <input
                    onChange={e => setKey(e.target.value)}
                    value={key}
                        
                        type="text"
                        
                        className="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-96 sm:text-2xl border-gray-300 rounded-md text-center"
                        placeholder="What is the key?"
                    />
                </div>
                <button
                    onClick={handleClick}
                    type="submit"
                    className="mt-2 w-auto inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 sm:w-auto"
                >
                    Submit
                </button>
                </form>
            </div>


            
          </div>
          
        </div>
        <Footer currentKey={currentKey} />
      </div>
    )
  }
  