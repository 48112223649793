import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import Footer from "../components/Footer";
import ModaalMessage from "../methods/ModalMessage";

export default function Riddle() {
    const [value, setValue] = useState('');

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        if (value === '5761044') {
            navigate('/binary')
        } else {
            setValue('')
            navigate('/riddle')
        }
    }

    const levelCompleted = "2nd"
    const currentKey = "3rd"

    return (
        <div>
            <ModaalMessage level={levelCompleted} />
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <span className="block text-base text-center text-emerald-500 font-semibold tracking-wide uppercase">
                Riddle: Key 3
              </span>
                <h2 className="text-3xl font-extrabold text-stone-700 sm:text-4xl">
                    <span className="block"><span className="text-stone-800">Seven</span> cows heard <span className="text-stone-800">one</span> chicken. Never love <span className="text-stone-800">one two</span> death. <span className="text-stone-800">Four</span> there was never a <span className="text-stone-800">times</span> with a chicken <span className="text-stone-800">eight-one</span> cow?</span>
                </h2>
                <form>
                <div className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 ">
                    <input
                        onChange={e => setValue(e.target.value)}
                        type="text"
                        value={value}
                        className="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-96 sm:text-2xl border-gray-300 rounded-md text-center"
                        placeholder="What is the key?"
                    />
                </div>
                <button
                    onClick={handleClick}
                    type="submit"
                    className="mt-8 w-auto inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 sm:w-auto"
                >
                    Submit
                </button>
                </form>
            </div>
            <Footer currentKey={currentKey} />
        </div>
    )
}
