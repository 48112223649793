import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

/* This example requires Tailwind CSS v2.0+ */
export default function WordPuzzle() {
    const [val1, setVal1] = useState('')
    const [val2, setVal2] = useState('')
    const [val3, setVal3] = useState('')
    const [val4, setVal4] = useState('')
    const [val5, setVal5] = useState('')
    const [val6, setVal6] = useState('')
    const [val7, setVal7] = useState('')
    const [val8, setVal8] = useState('')
    const [val9, setVal9] = useState('')
    let [count, setCount] = useState(0)

    let letters = []
    const navigate = useNavigate();

    const handleClear = () => {
        setVal1('')
        setVal2('')
        setVal3('')
        setVal4('')
        setVal5('')
        setVal6('')
        setVal7('')
        setVal8('')
        setVal9('')
    }

    let validLetters = ['t', 'e', 'g', 'k', 'a'];


    const handleCheck = (e, prev) => {
        e.preventDefault();
        letters.push(val1.toLowerCase(), val2.toLowerCase(), val3.toLowerCase(), val4.toLowerCase(), val5.toLowerCase(), val6.toLowerCase(), val7.toLowerCase(), val8.toLowerCase(), val9.toLowerCase())    


        if (val1 === val2 || val1 === val3 || val2 === val3) {
            console.log("faile row 1")
        } else if (val4 === val5 || val4 === val6 || val5 === val6) {
            console.log("faile row 2")
        } else if (val7 === val8 || val7 === val9 || val8 === val9) {
            console.log("faile row 3")
        } else if (val1 === val4 || val1 === val7 || val4 === val7) {
            console.log("faile col 1")
        } else if (val2 === val5 || val2 === val8 || val5 === val8) {
            console.log("faile col 2")
        } else if (val3 === val6 || val3 === val9 || val6 === val9) {
            console.log("faile col 3")
        } else if (val1 === val5 || val1 === val9 || val5 === val9) {
            console.log("faile dia 1")
        } else if (val3 === val5 || val3 === val7 || val5 === val7) {
            console.log("faile dia 2")
        } else {
            let completedCount = 0
            for (let i = 0; i < letters.length; i++) {
                let letter = letters[i];                
                if (validLetters.some(l => l === letter)) {
                    completedCount += 1
                    if (completedCount === 9) {
                        navigate('articles')
                    }
    
                } else {
                    console.log("letters dont match!")
                    completedCount = 0;
                }
            } 
        }

    }






    return (
        <div className="bg-white">
            <form>
                <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">

                        <div>
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                                Using ONLY the Letters T-E-G-K-A
                            </h2>
                            <p className="mt-3 max-w-3xl text-lg text-stone-800">
                                Fill this 3x3 with letters from the word "Tegka".

                            </p>
                            <ol className="mt-4">
                                <li className="list-decimal">Every box MUST contain a letter.</li>
                                <li className="list-decimal">No row, column, or diagonal can use the same letter twice.</li>
                                <li className="list-decimal">Each direction must contain three different letters</li>
                                <li className="list-decimal">You can ONLY use the letters in TEGKA</li>
                            </ol>

                            <div className="mt-8 sm:flex">
                                <div className="rounded-md shadow">
                                    <div className="flex items-center justify-center">

                                        <button
                                            onClick={handleCheck}
                                            value="submit"
                                            className="w-full px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700"
                                        >
                                            Solve Key
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-3 sm:mt-0 sm:ml-3 flex items-center justify-center">
                                    <button
                                        onClick={handleClear}
                                        className="w-full px-5 py-3 border border-transparent text-base font-medium rounded-md text-emerald-700 bg-emerald-100 hover:bg-emerald-200"
                                    >
                                        Reset Puzzle
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 grid grid-cols-3 gap-1 md:grid-cols-3 lg:mt-0 lg:grid-cols-3 ">
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28 bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal1(e.target.value)}
                                    value={val1}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1" ></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal2(e.target.value)}
                                    value={val2}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal3(e.target.value)}
                                    value={val3}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal4(e.target.value)}
                                    value={val4}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal5(e.target.value)}
                                    value={val5}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal6(e.target.value)}
                                    value={val6}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal7(e.target.value)}
                                    value={val7}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal8(e.target.value)}
                                    value={val8}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>
                            <div className="col-span-1 flex justify-center p-2 h-28 w-28  bg-emerald-400 rounded-md">
                                <input
                                    onChange={e => setVal9(e.target.value)}
                                    value={val9}
                                    className="text-center shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full text-5xl border-gray-300 rounded-md uppercase font-extrabold" maxLength="1"></input>
                            </div>

                        </div>

                    </div>
                </div>
            </form>
        </div>
    )
}
