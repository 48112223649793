import { QuestionMarkCircleIcon, KeyIcon } from "@heroicons/react/solid";

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

 
  export default function Footer(props) {
    const [open, setOpen] = useState(false)
    const [newOpen, setNewOpen] = useState(false)

    const popModal = () => {
        setOpen(true)
    }

    const popQuestion = () => {
      setNewOpen(true)
    }

    



    return (
      <footer className={props.bgColor}>
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 relative">          
          <p className="mt-14 text-center text-xl font-bold text-stone-700">Everything you need to know is on this page</p>
          <h1 className="pb-3 block text-center font-bold text-emerald-500">Happy Hacking</h1>
          <div className="flex items-center justify-center"><button><QuestionMarkCircleIcon onClick={popQuestion} className="h-8 w-8 text-stone-300 group-hover:text-emerald-400"/></button> </div>
          <button
          onClick={popModal}
        type="button"
        className="absolute bottom-5 right-5 inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
      >
        <KeyIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100">
                    <KeyIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-stone-900">
                      You are on your {props.currentKey} key
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-stone-500">
                        Once you solve this puzzle you will earn your {props.currentKey} key of the game.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600  sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                   Keep Hacking
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>


    <Transition.Root show={newOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setNewOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100">
                    <QuestionMarkCircleIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-stone-900">
                      Everything you need is on this page
                    </Dialog.Title>
                    <div className="mt-2">
                    <div className=" prose prose-sky prose-sm text-gray-500 mx-auto  text-left ">
                  <ul className='' >
                    <li>You must obtain 10 Keys to win the game.</li>
                    <li>Each puzzle contains one key.</li>
                    <li>The clues to each puzzle will always be right in front of you.</li>
                    <li>The further you get, the harder the puzzles will become.</li>
                    <li>Be the first to complete all 10 puzzles and unlock Tegka</li>
                    <li>If you feel there is no answer, there is, all puzzles are solvable.</li>
                    <li>Dont give up and happy Hacking</li>
                  </ul>
                </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600  sm:text-sm"
                    onClick={() => setNewOpen(false)}
                  >
                   Keep Hacking
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
                   
        </div>
        
      </footer>
    )
  }
  