/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'


export default function Navbar(props) {
    return (
        <Disclosure as="nav" className="bg-white">
            {({ open }) => (
                <>
                    <div className="mx-auto mt-6 px-4 sm:px-6 lg:px-8 flex justify-center">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-14 w-auto"
                                        src={props.logo}
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden lg:block h-14 w-auto"
                                        src={props.logo}
                                        alt="Workflow"
                                    />
                                </div>
                               </div>                           
                        </div>
                    </div>                    
                </>
            )}
        </Disclosure>
    )
}
