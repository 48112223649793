import Home from "./pages.js/Home";
import { Routes, Route } from 'react-router-dom'
import Navbar from "./components/Navbar";
import logo from './assets/brandlogo.svg';
import Welcome from "./pages.js/Welcome";
import SquareGame from "./pages.js/SquaresGame";
import Riddle from "./pages.js/Riddle";
import Binary from "./pages.js/Binary";
import Crypto from "./pages.js/Crypto";
import WordPuzzle from "./pages.js/WordPuzzle";


function App() {
  return (
    <div>
      <Navbar logo={logo} />
       <Routes>
        <Route path="/" element={<Welcome/>}/>
        <Route path="/start" element={<Home/>}/>
        <Route path="/password" element={<SquareGame/>}/>
        <Route path="/riddle" element={<Riddle/>}/>
        <Route path="/binary" element={<Binary/>}/>
        <Route path="/crypto" element={<Crypto/>}/>
        <Route path="/words" element={<WordPuzzle/>}/>
       </Routes>
      
    </div>
  );
}

export default App;
