import { useNavigate } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import Footer from '../components/Footer'

export default function Home() {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('') 

  const navigate = useNavigate()
  
  const handleClick = (e) => {
    e.preventDefault();
    if (userName === "Welcome" && password === "password") {
      navigate('/password')
    } else {
      setPassword('')
      setUserName('')
    }
  }

  const currentKey = "1st";

  return (
    <>        
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        
        <div className="max-w-md w-full space-y-8">
        <span className="block text-base text-center text-emerald-500 font-semibold tracking-wide uppercase">
                Login: Key 1
              </span>
          <div>         
            <h2 className="mt-6 text-center text-xl font-semibold text-emerald-500"><span className='font-bold text-stone-900'>Your username:</span> Welcome</h2>
            <h2 className="text-center text-xl font-bold text-stone-900">What is your <span className='text-xl font-semibold text-emerald-500'>password</span>?</h2>            
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Username
                </label>
                <input
                onChange={e => setUserName(e.target.value)}
                value={userName}                  
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-stone-300 placeholder-stone-500 text-stone-900 rounded-t-md focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                onChange={e => setPassword(e.target.value)}
                value={password}
                  type="password"
                  
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-stone-300 placeholder-stone-500 text-stone-900 rounded-b-md focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">              

            </div>

            <div>
              <button
              onClick={handleClick}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-emerald-200 group-hover:text-emerald-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer currentKey={currentKey} /> 
    </>
  )
}
