import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import ModaalMessage from '../methods/ModalMessage';
import Footer from '../components/Footer';

const people = [
    {
        name: "blue",
        color: ['bg-white', 'bg-blue-500', 'bg-red-500', 'bg-yellow-400', 'bg-emerald-500'],
        value: 1
    },
    {
        name: "red",
        color: ['bg-white', 'bg-red-500', 'bg-yellow-400', 'bg-emerald-500', 'bg-blue-500'],
        value: 2
    },
    {
        name: "yellow",
        color: ['bg-white', 'bg-yellow-400', 'bg-emerald-500', 'bg-blue-500', 'bg-red-500',],
        value: 3

    },
    {
        name: "blue",
        color: ['bg-white', 'bg-emerald-500', 'bg-blue-500', 'bg-red-500', 'bg-yellow-400'],
        value: 4

    },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SquareGame() {
    const [countBlue, setCountBlue] = useState(0);
    const [countRed, setCountRed] = useState(0);
    const [countYellow, setCountYellow] = useState(0);
    const [countemerald, setCountemerald] = useState(0);

    const handleClick = () => {
        let ctrColor = countBlue >= 4 ? 0 : countBlue + 1
        setCountBlue(ctrColor);
    }

    const handleClickRed = () => {
        let ctrRed = countRed >= 4 ? 0 : countRed + 1
        setCountRed(ctrRed);
    }

    const handleClickYellow = () => {
        let ctrYellow = countYellow >= 4 ? 0 : countYellow + 1
        setCountYellow(ctrYellow);
    }

    const handleClickemerald = () => {
        let ctremerald = countemerald >= 4 ? 0 : countemerald + 1
        setCountemerald(ctremerald);
    }

    

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (combo === '4332334') {
            navigate('/riddle')
        } else {
            setCountBlue(0)
            setCountemerald(0)
            setCountYellow(0)
            setCountRed(0)
            navigate('/password')
        }
    }

    

    const combo = [countBlue, countemerald, countYellow, countRed, countYellow, countYellow, countBlue].join('')
    const levelCompleted = "1st"
    const currentKey = "2nd"
    const bgColor = "bg-stone-900"

    

    


    return (
        <> 
        <ModaalMessage level={levelCompleted} />          
         <div className="mt-9 bg-stone-900">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8 bg-stone-900 rounded-xl shadow">
      <span className="block text-base text-center text-emerald-500 font-semibold tracking-wide uppercase">
                Light it Up: Key 2
              </span>
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">All must be <span className='text-yellow-400'>on</span>.</span>
          <span className="block">There can only be <span className='text-blue-400'>one</span>.</span>
          <span className="block">All have more than <span className="text-red-400">one</span>.</span>
        </h2>
        
        <ul className="mt-7 grid grid-cols-4 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
                    <button
                        onClick={handleClick}
                        className={classNames('inline-flex items-center justify-center  px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white', people[0].color[countBlue])}>On</button>
                </li>
                <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
                    <button
                        onClick={handleClickRed}
                        className={classNames('inline-flex items-center justify-center  px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white', people[1].color[countRed])}>On</button>
                </li>
                <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
                    <button
                        onClick={handleClickYellow}
                        className={classNames('inline-flex items-center justify-center  px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white', people[2].color[countYellow])}>On</button>
                </li>
                <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
                    <button
                        onClick={handleClickemerald}
                        className={classNames('inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white', people[3].color[countemerald])}>On</button>
                </li>
            </ul>
        <button
        onClick={handleSubmit}          
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-400 sm:w-auto"
        >
          Submit Key
        </button>
      </div>
    </div> 
           
    <Footer currentKey={currentKey} bgColor={bgColor} /> 
        </>
    )
}
