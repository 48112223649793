import { Link } from 'react-router-dom';
import ModaalMessage from '../methods/ModalMessage';


export default function Welcome() {
  return (
    <div className='bg-white'>
      <div className="relative overflow-hidden mb-8">
        <div className="relative ">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-9">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-stone-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Welcome to </span>{' '}
                <span className="block text-emerald-400 xl:inline">Tegka</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-stone-500 sm:text-lg md:mt-3 md:text-xl md:max-w-3xl">
                Anybody can win. Anybody can play. All the clues you need are right on the page.
              </p>
              <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg  mx-auto">
                  <h1>
                    <span className="mt-4 block sm:text-4xl text-center text-emerald-400 font-bold tracking-wide text-shadow">
                      Can You Hack It?
                    </span>



                    <div className="relative mt-6">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-stone-300" />
                      </div>
                    </div>
                    <br></br>
                    <span className="mt-4 block text-3xl text-center leading-8 font-extrabold tracking-tight text-stone-900 sm:text-4xl">
                      How to Play Tegka
                    </span>
                  </h1>

                </div>
                <div className="mt-6 prose prose-sky prose-md text-gray-500 mx-auto max-w-md text-left ">
                  <ol className='list-decimal ' >
                    <li>You must obtain 10 Keys to win the game.</li>
                    <li>Each puzzle contains one key.</li>
                    <li>When you complete a puzzle you will obtain that key.</li>
                    <li>That key will ALWAYS unlock the next puzzle. </li>
                    <li>Keep the keys and never share them.</li>
                    <li>The clues to each puzzle will always be right in front of you.</li>
                    <li>The further you get, the harder the puzzles will become.</li>
                    <li>Be the first to complete all 10 puzzles and unlock Tegka</li>
                    <li>If you feel there is no answer, there is, all puzzles are solvable.</li>
                    <li>Dont give up, <span className="text-stone-900 font-semibold">this is not supposed to be easy</span> and Happy Hacking</li>
                  </ol>
                </div>
              </div>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <Link
                    to="/start"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-400 hover:bg-emerald-500 md:py-4 md:text-lg md:px-10"
                  >
                    Play
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
