import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import Footer from "../components/Footer"
import ModaalMessage from "../methods/ModalMessage"

/* This example requires Tailwind CSS v2.0+ */
export default function Binary() {
    const [ firstValue, setFirstValue ] = useState('')

    const navigate = useNavigate()

   
    const handleClick = (e) => {
        e.preventDefault();
        if (firstValue.toLowerCase() === 'deoxyribonucleic acid' || firstValue.toLowerCase() === 'deoxyribonucleic' || firstValue.toLowerCase() === 'deoxyribonucleic ') {
            navigate('/crypto')
        } else { 
          setFirstValue('')
        } 

    }

    const levelCompleted = {
      lastLevel: '3rd',
      currentLevel: '4th',
      peopleCompleted: 10

    }

    const currentKey = '4th'


    return (
      <div className="mt-8 bg-stone-50 pt-12 sm:pt-16">
        <ModaalMessage level={levelCompleted.lastLevel} completed={levelCompleted.peopleCompleted}/>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
          <span className="block text-base text-center text-emerald-500 font-semibold tracking-wide uppercase">
                Coded: Key 4
              </span>
            <h2 className="text-3xl font-extrabold text-stone-900 sm:text-4xl">
              Not everything is so binary
            </h2>
            <p className="mt-3 text-xl text-stone-500 sm:mt-4">
              Pay close attention or you might miss the link between machines and humans.
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-stone-50" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-stone-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-stone-500">T 01000001 H 01100010 E 01100010 01110010 W 01100101 O 01110110 R 01101001 D 01100001  01110100 01100101 </dt>
                    <dd className="order-1 text-5xl font-extrabold text-emerald-500">Did</dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-stone-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-stone-500">I 01010011 S 01110000 01100101 01101100 01101100 T 00100000 H 01101001 E 01110100 K 00100000 E 01101111 Y 01110101 01110100  </dt>
                    <dd className="order-1 text-5xl font-extrabold text-emerald-500">Not</dd>
                  </div>
                  <div className="flex flex-col border-t border-stone-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-stone-500">R 01010100 E 01110111 A 01101111 L 00100000 N 01010111 A 01101111 M 01110010 E 01100100 01110011  <br></br><a href="https://firebasestorage.googleapis.com/v0/b/tegka-cff05.appspot.com/o/strand-of-dna.jpg?alt=media&token=e54b422a-f3f9-4feb-987c-52e7a61f95ef" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-white">Key Name</a></dt>
                    <dd className="order-1 text-5xl font-extrabold text-emerald-500">Accept</dd>  
                                      
                  </div>
                  
                </dl> 
                <form>
                <div className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 ">
                    <input
                        onChange={e => setFirstValue(e.target.value)}
                        type="text"
                        value={firstValue}
                        className="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-96 sm:text-2xl border-gray-300 rounded-md text-center"
                        placeholder="What is the key?"
                    />
                </div>
                <div className="mt-1 w-full inline-flex items-center justify-center  ">
                <button
                    onClick={handleClick}
                    type="submit"
                    className=" px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600"
                >
                    Submit
                </button>
                </div>
                </form>         
                
              </div>
              
            </div>
            
          </div>
        </div>
        <Footer currentKey={currentKey} />
      </div>
      
    )
  }
  